<template>
  <div>
    <vehicles-add></vehicles-add>
  </div>
</template>

<script>
import vehiclesAdd from "@/components/ecologist/classifiers/vehicles/vehiclesAdd";
export default {
  name: "vehiclesAddView",
  components: { vehiclesAdd },
};
</script>

<style scoped></style>
